var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex h-full w-full relative capitalize border-t border-pfm-primary",on:{"mouseleave":function($event){return _vm.onNodeleave()}}},[_c('div',{staticClass:"hidden flex-col w-55 lg:w-80",class:_vm.visibleClasses},[_c('div',{staticClass:"w-full overflow-y-auto scrollbar-invisible",attrs:{"data-scroll-lock-scrollable":""}},[_c('div',{staticClass:"font-bold text-black ml-3 mt-4"},[_vm._v("Categorieën")]),_vm._v(" "),_c('ul',{staticClass:"py-2.5 border-r"},_vm._l((_vm.categories),function(category){return _c('li',{key:category.id,staticClass:"transition-all",class:[
            _vm.activeId === category.id
              ? 'bg-pfm-primary-lighter -mr-0.5 pr-0.5'
              : ' border-transparent' ],on:{"mouseenter":function($event){return _vm.onNodeHover(category)}}},[_c('NuxtLink',{staticClass:"flex items-center py-3 px-3 group/li",attrs:{"to":_vm.localePath(
                category.url
                  ? {
                      name: 'c-all',
                      params: { pathMatch: category.url },
                    }
                  : { name: 'index' }
              )}},[(category.smallImg)?_c('AppLazyImage',{staticClass:"w-4 h-4 lg:w-6 lg:h-6 mr-2 lg:mr-3 overflow-hidden ",attrs:{"src":category.smallImg,"alt":category.name,"title":category.name,"preload":true,"format":"webp","fit":"contain","img-class":"w-full h-full object-contain"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"lowercase first-letter:uppercase"},[_vm._v(_vm._s(category.name))]),_vm._v(" "),_c('BaseIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeId === category.id),expression:"activeId === category.id"}],staticClass:"h-3 w-3 ml-auto",attrs:{"name":"pfm-arrow-right"}})],1),_vm._v(" "),_c('AppLazyHydrate',{attrs:{"never":"","trigger-hydration":_vm.activeId === category.id}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeId === category.id),expression:"activeId === category.id"}],staticClass:"absolute left-55 lg:left-80 w-128 lg:w-160 inset-y-0 z-10 overflow-y-auto scrollbar-invisible",attrs:{"data-scroll-lock-scrollable":""}},[_c('div',{staticClass:"font-bold text-black ml-3 mt-4"},[_c('span',{staticClass:"lowercase first-letter:uppercase block"},[_vm._v(_vm._s(category.name))])]),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(category.loaded),expression:"category.loaded"}],staticClass:"py-2.5 border-r w-55 lg:w-80"},[(category.children && category.children.length)?_vm._l((category.children),function(category2){return _c('li',{key:category2.id,staticClass:"break-inside-avoid-column group/li2",class:[
                      _vm.activeId2 === category2.id
                        ? 'bg-pfm-primary-lighter -mr-0.5 pr-0.5'
                        : ' border-transparent' ],on:{"mouseenter":function($event){return _vm.onNodeHover2(category2)}}},[_c('NuxtLink',{staticClass:"flex items-center py-3 px-3",attrs:{"to":_vm.localePath(
                          category2.url
                            ? {
                                name: 'c-all',
                                params: { pathMatch: category2.url },
                              }
                            : { name: 'index' }
                        )}},[(category2.smallImg)?_c('AppLazyImage',{staticClass:"w-4 h-4 lg:w-6 lg:h-6 mr-2 lg:mr-3 overflow-hidden",attrs:{"src":category2.smallImg,"alt":category2.name,"title":category2.name,"preload":true,"format":"webp","fit":"contain","img-class":"w-full h-full object-contain"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"lowercase first-letter:uppercase"},[_vm._v(_vm._s(category2.name))]),_vm._v(" "),_c('BaseIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeId2 === category2.id),expression:"activeId2 === category2.id"}],staticClass:"h-3 w-3 ml-auto",attrs:{"name":"pfm-arrow-right"}})],1),_vm._v(" "),_c('AppLazyHydrate',{attrs:{"never":"","trigger-hydration":_vm.activeId2 === category2.id}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeId2 === category2.id),expression:"activeId2 === category2.id"}],staticClass:"absolute left-55 lg:left-80 w-full max-w-full lg:w-80 inset-y-0 z-10 overflow-y-auto overflow-x-hidden scrollbar-invisible",attrs:{"data-scroll-lock-scrollable":""}},[_c('div',{staticClass:"font-bold text-black ml-3 mt-4"},[_c('span',{staticClass:"lowercase first-letter:uppercase block"},[_vm._v(_vm._s(category2.name))])]),_vm._v(" "),_c('div',{staticClass:"break-inside-avoid-column mt-2.5 -mr-0.5 pr-0.5 hover:bg-pfm-primary-lighter group/div"},[_c('NuxtLink',{staticClass:"flex items-center py-3 px-3",attrs:{"to":_vm.localePath(
                                category2.url
                                  ? {
                                      name: 'c-all',
                                      params: { pathMatch: category2.url },
                                    }
                                  : { name: 'index' }
                              )}},[_c('div',{staticClass:"w-4 h-4 lg:w-6 lg:h-6 mr-2 lg:mr-3 overflow-hidden"},[_c('nuxt-img',{staticClass:"w-full h-full object-contain",attrs:{"src":"/images/icons/infinity.png","alt":("Alles van " + (category2.name)),"title":("Alles van " + (category2.name)),"preload":true,"format":"png","fit":"contain"}})],1),_vm._v(" "),_c('span',[_vm._v("Alles in ")]),_c('span',{staticClass:"lowercase first-letter:uppercase"},[_vm._v(_vm._s(category2.name))])])],1),_vm._v(" "),_c('ul',{staticClass:"pb-2.5 border-r"},[(category2.children && category2.children.length)?_vm._l((category2.children),function(category3){return _c('li',{key:category3.id,staticClass:"break-inside-avoid-column -mr-0.5 pr-0.5 hover:bg-pfm-primary-lighter group/li3"},[_c('NuxtLink',{staticClass:"flex items-center py-3 px-3",attrs:{"to":_vm.localePath(
                                    category3.url
                                      ? {
                                          name: 'c-all',
                                          params: { pathMatch: category3.url },
                                        }
                                      : { name: 'index' }
                                  )}},[(category3.smallImg)?_c('AppLazyImage',{staticClass:"w-4 h-4 lg:w-6 lg:h-6 mr-2 lg:mr-3 overflow-hidden",attrs:{"src":category3.smallImg,"alt":category3.name,"title":category3.name,"preload":true,"format":"webp","fit":"contain","img-class":"w-full h-full object-contain"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"lowercase first-letter:uppercase"},[_vm._v(_vm._s(category3.name))])],1)],1)}):_vm._e()],2),_vm._v(" "),(!category2.loaded)?_c('div',{staticClass:"absolute inset-0 flex items-center justify-center"},[_c('BaseIcon',{attrs:{"name":"spinner","spin":""}})],1):_vm._e()])])],1)}):_vm._e()],2),_vm._v(" "),(!category.loaded)?_c('div',{staticClass:"absolute inset-0 flex items-center justify-center"},[_c('BaseIcon',{attrs:{"name":"spinner","spin":""}})],1):_vm._e()])])],1)}),0)])]),_vm._v(" "),(_vm.slots.default)?_c('div',{staticClass:"flex-1 flex relative"},[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }