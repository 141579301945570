
// https://github.com/maoberlehner/vue-lazy-hydration
import LazyHydrate from 'vue-lazy-hydration'

// @vue/component
export default {
  ...LazyHydrate,

  name: 'AppLazyHydrate',
}
